import { all, takeLatest, put } from "redux-saga/effects";
import * as actionLabels from "../../actionLabels";
import {
  cancelTransactionFail,
  cancelTransactionStart,
  cancelTransactionSuccess,
  SuccessTransactionFail,
  getPriceFail,
  getPriceStart,
  getPriceSuccess,
  SuccessTransactionStart,
  transactionFail,
  transactionStart,
  transactionSuccess,
  SuccessTransactionSuccess,
} from "../../actions";
import { axios } from "../../../http";
import { toast } from "react-toastify";
import { options } from "../../../utils/toastOptions";

function* transactionSaga(action) {
  const { payload } = action;
  yield put(transactionStart());
  try {
    const response = yield axios
      .post("/fiat/transact", payload)
      .then((response) => response)
      .catch((error) => error);
    if (response.status === 200) {
      yield put(transactionSuccess(response.data.data.message));
      yield toast.success(response.data.data.message, options);
    } else if (response !== 200) {
      yield put(transactionFail(response.response.data.message));
      yield toast.error(response.response.data.message, options);
    } else {
      yield put(transactionFail(response.response.data.message));
      yield toast.error(response.response.data.message, options);
    }
  } catch (error) {
    yield put(transactionFail("Something went wrong. Please try again."));
    yield toast.error("Something went wrong. Please try again.", options);
  }
}

function* getPriceSaga(action) {
  const { data, setShowCancelBtn } = action.payload;
  yield put(getPriceStart());
  try {
    const response = yield axios
      .post("/set-price", data)
      .then((response) => response)
      .catch((error) => error);
    if (response.status === 200) {
      yield put(getPriceSuccess(response.data.data));
    } else if (response.response.status === 403) {
      yield setShowCancelBtn(false);
      yield put(getPriceFail(response.response.data.message));
    } else {
      yield put(getPriceFail(response.response.data.message));
    }
  } catch (error) {
    yield put(getPriceFail("Something went wrong. Please try again."));
    yield toast.error("Something went wrong. Please try again.", options);
  }
}

function* cancelTransactionSaga(action) {
  const { payload } = action;
  yield put(cancelTransactionStart());
  try {
    const response = yield axios.patch("/transaction/cancel", payload);
    if (response.status === 200) {
      yield put(cancelTransactionSuccess(response?.data?.message));
      yield toast.success(response?.data?.message, options);
      action.payload.handleCanceltransaction({
        orgId: response?.data?.orgId,
        orderId: response?.data?.orderId,
      });
    } else if (response !== 200) {
      yield put(cancelTransactionFail(response?.response?.message));
      yield toast.error(response?.response?.message, options);
    } else {
      yield put(cancelTransactionFail(response?.response?.message));
      yield toast?.error(response?.response?.message, options);
    }
  } catch (error) {
    yield put(cancelTransactionFail(error?.data?.message));
    yield toast.error(error?.data?.message, options);
  }
}
function* SuccessTransactionSaga(action) {
  const { payload } = action;
  yield put(SuccessTransactionStart());
  try {
    const response = yield axios.post("/transaction/verify", payload.data);
    if (response.status === 200) {
      yield put(SuccessTransactionSuccess(response?.data?.message));
      yield toast.success(response?.data?.message, options);
      action.payload.handlesuccesstransaction({
        orgId: response?.data?.orgId,
        orderId: response?.data?.orderId,
      });
    } else if (response !== 200) {
      yield put(SuccessTransactionFail(response?.response?.message));
      yield toast.error(response?.response?.message, options);
    } else {
      yield put(SuccessTransactionFail(response?.response?.message));
      yield toast?.error(response?.response?.message, options);
    }
  } catch (error) {
    yield put(SuccessTransactionFail(error?.data?.message));
    console.log(error.response);
    yield toast.error(error?.data?.message, options);
  }
}

export default function* rootsaga() {
  yield all([
    yield takeLatest(actionLabels.TRANSACTION_SAGA, transactionSaga),
    yield takeLatest(actionLabels.GET_PRICE_SAGA, getPriceSaga),
    yield takeLatest(
      actionLabels.CANCEL_TRANSACTION_SAGA,
      cancelTransactionSaga
    ),
    yield takeLatest(
      actionLabels.SUCCESS_TRANSACTION_SAGA,
      SuccessTransactionSaga
    ),
  ]);
}
