import React, { useEffect, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import "./App.css";
import Loader from "./components/Loader/Loader";
import WrongNetworkModal from "./components/WrongNetworkModal";
import Layout from "./views/Layout/Layout";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getPrice, setSearchParams } from "./store/actions";
import useConfig from "./utils/useConfig";
import { options } from "./utils/toastOptions";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const App = () => {
  const { active, chainId, account } = useWeb3React();
  const [isValidNetwork, setIsValidNetwork] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [network, setNetwork] = useState("");
  const dispatch = useDispatch();
  const prevAcc = usePrevious(account);
  const config = useConfig();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  console.log(process.env.NODE_ENV);
  useEffect(() => {
    if (chainId === Number(config.BSC_CHAIN_ID)) setNetwork("BSC");
    else if (chainId === Number(config.POLYGON_CHAIN_ID)) setNetwork("MATIC");
    else if (chainId === Number(config.ETH_CHAIN_ID)) setNetwork("ETHEREUM");
  }, [chainId]);
  useEffect(() => {
    const search_params =
      params || JSON.parse(localStorage.getItem("searchParams"));
    if (prevAcc !== account)
      dispatch(
        getPrice({
          userWalletAddress: account,
          chainCode: network,
          ...search_params,
        })
      );
  }, [account]);
  useEffect(() => {
    if (
      chainId === Number(config.BSC_CHAIN_ID) ||
      chainId === Number(config.POLYGON_CHAIN_ID) ||
      chainId === Number(config.ETH_CHAIN_ID)
    ) {
      setIsValidNetwork(true);
      setOpenChangeModal(false);
      localStorage.setItem("shouldEagerConnect", true);
      setLoading(false);
    } else {
      setIsValidNetwork(false);
      setOpenChangeModal(true);
      if (account || prevAcc) {
        localStorage.setItem("shouldEagerConnect", false);
      }
    }
  }, [chainId, account]);

  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      dispatch(setSearchParams(params));
      localStorage.setItem("searchParams", JSON.stringify(params));
    }
  }, [params]);
  let mainContent = (
    <>
      <Route
        exact
        path="/login"
        component={React.lazy(() => import("./views/Login/Login"))}
      />
      {!active && <Redirect to="/login" />}
    </>
  );

  if (active && isValidNetwork) {
    localStorage.setItem("shouldEagerConnect", true);

    mainContent = (
      <>
        <Route
          path="/"
          component={React.lazy(() =>
            import("./views/MainContainer/MainContainer")
          )}
        />
      </>
    );
  }

  const onChangeNetworkClickBinance = async () => {
    setLoading(true);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(config.BSC_CHAIN_ID)
              .toString(16)
              .toString(16)}`,
          },
        ],
      });
      setLoading(false);
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                rpcUrls: [config.RPC_URL_BSC],
                chainId: `0x${Number(config.BSC_CHAIN_ID)
                  .toString(16)
                  .toString(16)}`,
                nativeCurrency: {
                  name: "Binance",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: [config.BLOCK_URL_BSC],
                chainName: config.BSC_CHAIN_NAME,
              },
            ],
          });
        } catch (addError) {
          toast.error(addError, options);
        }
      }
      setLoading(false);
    }
  };
  const onChangeNetworkClickMatic = async () => {
    setLoading(true);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(config.POLYGON_CHAIN_ID)
              .toString(16)
              .toString(16)}`,
          },
        ],
      });
      setLoading(false);
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                rpcUrls: [config.RPC_URL_POLYGON],
                chainId: `0x${Number(config.POLYGON_CHAIN_ID)
                  .toString(16)
                  .toString(16)}`,
                nativeCurrency: {
                  name: "Polygon",
                  symbol: "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: [config.BLOCK_URL_POLYGON],
                chainName: config.POLYGON_CHAIN_NAME,
              },
            ],
          });
        } catch (addError) {
          toast.error(addError, options);
        }
      }
      setLoading(false);
    }
  };
  const onChangeNetworkClickEth = async () => {
    setLoading(true);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(config.ETH_CHAIN_ID)
              .toString(16)
              .toString(16)}`,
          },
        ],
      });
      setLoading(false);
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                rpcUrls: [config.RPC_URL_ETH],
                chainId: `0x${Number(config.ETH_CHAIN_ID)
                  .toString(16)
                  .toString(16)}`,
                nativeCurrency: {
                  name: "Ethereum",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: [config.BLOCK_URL_ETH],
                chainName: config.ETH_CHAIN_NAME,
              },
            ],
          });
        } catch (addError) {
          toast.error(addError, options);
        }
      }
      setLoading(false);
    }
  };

  return (
    <React.Suspense fallback={<Loader />}>
      <WrongNetworkModal
        show={openChangeModal && active}
        onChangeNetworkClickMatic={onChangeNetworkClickMatic}
        onChangeNetworkClickBinance={onChangeNetworkClickBinance}
        onChangeNetworkClickEth={onChangeNetworkClickEth}
        disabledButton={loading}
      />
      <BrowserRouter>
        <Layout>
          <Switch>{mainContent}</Switch>
        </Layout>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
